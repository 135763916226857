var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Nom entreprise","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title","state":errors.length > 0 ? false:null},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Adresse","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title","state":errors.length > 0 ? false:null},model:{value:(_vm.company.address),callback:function ($$v) {_vm.$set(_vm.company, "address", $$v)},expression:"company.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Matricule fiscale","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"tax_identification_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title","state":errors.length > 0 ? false:null},model:{value:(_vm.company.tax_identification_number),callback:function ($$v) {_vm.$set(_vm.company, "tax_identification_number", $$v)},expression:"company.tax_identification_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Tel1","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"phone1","rules":"required|integer|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title","state":errors.length > 0 ? false:null,"maxlength":"8","type":"number"},model:{value:(_vm.company.phone1),callback:function ($$v) {_vm.$set(_vm.company, "phone1", $$v)},expression:"company.phone1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Tel2","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.company.phone2),callback:function ($$v) {_vm.$set(_vm.company, "phone2", $$v)},expression:"company.phone2"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Logo ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.company.logo,"height":"110","width":"170"}})],1),_c('b-media-body',[_c('small',{staticClass:"text-muted"},[_vm._v("Entrer le logo de votre entreprise.")]),_c('b-card-text',{staticClass:"my-50"},[_c('b-link',{attrs:{"id":"blog-image-text"}})],1),_c('div',{staticClass:"d-inline-block"},[_c('validation-provider',{attrs:{"name":"logo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputEl",attrs:{"placeholder":"Choose file","state":errors.length > 0 ? false:null},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.company.logo),callback:function ($$v) {_vm.$set(_vm.company, "logo", $$v)},expression:"company.logo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)]),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }